import React from "react"
import { Link } from "gatsby"

import { routes } from "@/utils/routes"
import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import WistiaPlayer from "@/components/WistiaPlayer"
import { StaticImage } from "gatsby-plugin-image"
import BackgroundSection from "@/components/BackgroundSection"
import Feature from "@/components/Feature"

type PageProps = {
  data: any
}

const SportingExcellence: React.FC<PageProps> = ({}) => (
  <Layout
    sectionOne={
      <>
        <StaticImage
          src="../../static/images/2018/03/sporting.jpg"
          className="absolute inset-0 -z-[1]"
          alt="Sporting Excellence"
        />
        <div className="container text-white pt-96 pb-4 flex flex-col items-center text-center">
          <h1 className="pb-2 md:pb-8 responsive-heading">
            THE <span className="text-primary">ROBERT HOWARD</span> POOL
          </h1>
          <div className="pt-8 flex justify-center">
            <img src="/images/down.png" alt="Down arrow" />
          </div>
        </div>
      </>
    }
  >
    <SEO title="Sporting Excellence" />
    <div className="container py-24">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-y-16">
        <h2 className="uppercase responsive-heading !leading-[0.9]">
          RBAI has a <span className="text-primary">very proud</span> <br />
          sporting heritage and <span className="text-primary">tradition</span>
        </h2>

        <WistiaPlayer
          videoStyle={{
            width: "100%",
            height: "100%",
            minHeight: "300px",
            maxHeight: "340px",
          }}
          videoID="jp57ors0xa"
        />

        <div className="leading-relaxed">
          <p className="pb-4">
            Alongside outstanding rugby and soccer pitches at Osborne, Cranmore
            and Bladon and a state of the art water-based hockey pitch at Shaw’s
            Bridge, RBAI boasts the best cricket facilities of any school in
            Ireland.
          </p>

          <p>
            Having produced 3 Olympians and 6 pupils who have competed in the
            Commonwealth Games, swimming remains extremely popular with more
            than 650 pupils participating each year leading to over 12,500
            individual usages for Physical Education and Games.{" "}
          </p>
        </div>

        <div>
          <p className="pb-4">
            The current pool also hosts waterpolo, rowing, competition swimming
            and utilisation by the local community.
          </p>

          <Link className="btn btn-primary btn-lg" to={routes.robertHoward()}>
            Read about Robert Howard - RBAI's most successful Swimmer
          </Link>
        </div>
      </div>
    </div>

    <BackgroundSection
      heading={
        <h2 className="responsive-heading">
          AN <span className="text-primary">AGEING</span>
          <br /> FACILITY
        </h2>
      }
      paragraphs={
        <p className="max-w-[790px]">
          Having served pupils for more than 50 years, the RBAI swimming pool is
          now beyond its economic life. As external provision of swimming pool
          time is severely limited in the Belfast area, the loss of our pool
          would have a profound impact. The swimming pool also forms a crucial
          part of the School’s community outreach strategy, and is used
          throughout the year by a wide range of groups.
        </p>
      }
      bg="/images/2018/03/ageing-facility.jpg"
    />

    <div className="container py-24">
      <div className="max-w-[700px]">
        <h2 className="responsive-heading uppercase pb-4">
          The <br />
          <span className="text-primary">Proposal</span>
        </h2>
        <p className="leading-relaxed pb-4">
          Following careful appraisal of a range of options, the Board of
          Governors plans to build a new six lane pool facilitating ‘learn to
          swim’ classes, competitive swimming, waterpolo and related activities
          such as sub aqua and canoeing lessons.
        </p>

        <p className="leading-relaxed pb-4">
          Built with energy efficient materials and a moveable floor, the new
          pool will provide a state of the art facility in the heart of the
          School.
        </p>
      </div>

      <div className="grid md:grid-cols-2 gap-8">
        <div className="col-md-6 col-sm-12">
          <img
            src="/images/2018/03/robert-howard-1.jpg"
            alt="RBAI - Robert Howard Pool"
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <img
            src="/images/2018/03/robert-howard-2.jpg"
            alt="RBAI - Robert Howard Pool"
          />
        </div>
      </div>
    </div>

    <hr />

    <div className="container py-24">
      <div className="flex flex-col lg:flex-row lg:items-end gap-4 justify-between">
        <h2 className="responsive-heading">
          IMPACTING
          <br />
          <span className="text-primary">PUPILS</span>{" "}
        </h2>
        <p className="max-w-[640px] lg:pl-[10%]">
          The proposed plans will give RBAI the best school swimming pool in
          Northern Ireland, enhancing the School by:{" "}
        </p>
      </div>
      <div className="grid gap-y-8 md:grid-cols-2 lg:grid-cols-4 pt-20">
        <Feature description="Providing a contemporary facility which will benefit 8000 pupils over the next 50 years." />
        <Feature description="Maintaining RBAI as the premier school for competitive swimming in Northern Ireland." />
        <Feature description="Providing another asset which will attract new pupils to the School." />
        <Feature description="Supporting RBAI’s community engagement strategy." />
      </div>
    </div>

    <BackgroundSection
      heading={
        <h2 className="responsive-heading">
          <span className="text-primary">EMERGING</span>
          <br /> TALENT
        </h2>
      }
      paragraphs={
        <>
          <p>
            In recent years RBAI has nurtured a number of leading swimmers,
            including James Brown, who has been selected to represent Northern
            Ireland at the 2018 Commonwealth Games in Australia. James won the
            Bath Cup and Otter Medley Cup in 2015 alongside Morgan Berryman
            (Ulster Junior 1500m record holder) and Cai Armstrong (NI High
            Performance Squad).
          </p>
          <p>
            Current pupil Jack McMillan won a Bronze medal at the Youth
            Commonwealth games in the Bahamas during 2017, after swimming the
            fastest time ever recorded at the Bath cup during the School’s
            recent victory.
          </p>
        </>
      }
      bg="/images/2018/03/swimming-block.jpg"
    />
  </Layout>
)

export default SportingExcellence
